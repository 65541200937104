import LadiesAgency from './LadiesAgency.vue'
import LadiesAgencyCreate from './Create.vue'
import LadiesAgencyShow from './Show.vue'

export default [
    {
        path: '/ladies_agency',
        name: 'LadiesAgency',
        component: LadiesAgency
    },
    {
        path: '/ladies_agency/create',
        name: 'LadiesAgencyCreate',
        component: LadiesAgencyCreate
    },
    {
        path: '/ladies_agency/edit/:id',
        name: 'LadiesAgencyEdit',
        component: LadiesAgencyCreate
    },
    {
        path: '/ladies_agency/:id',
        name: 'LadiesAgencyShow',
        component: LadiesAgencyShow
    },
]
